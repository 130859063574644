@use 'sass:math';

// MISC
body {
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  font-weight: initial;
}

.cursor {
  cursor: default;
}

.pointer {
  cursor: pointer;
}

.svg-stroke {
  stroke: $gray-700;

  &.selected {
    stroke: $primary;
  }
}

.bg-light-hover:hover {
  background-color: $gray-100;
}

.btn-ghost-primary.bg-light-hover {
  filter: brightness(95%);
  /* darken by 5% */
}

.dropzone-drop {
  background-color: $gray-100;
}

@mixin text-contrast($n) {
  $color-brightness: round(math.div((red($n) * 299) + (green($n) * 587) + (blue($n) * 114), 1000));
  $light-color: round(math.div((red(#ffffff) * 299) + (green(#ffffff) * 587) + (blue(#ffffff) * 114), 1000));

  @if abs($color-brightness) < math.div($light-color, 2) {
    color: white;
  }

  @else {
    color: black;
  }
}

// Background color utilities
.bg-primary {
  @include text-contrast($primary);

  &.clickable:hover {
    background-color: shade-color($primary, 15%) !important;
    @include text-contrast(shade-color($primary, 15%));
  }
}

.bg-secondary {
  @include text-contrast($secondary);

  &.clickable:hover {
    background-color: shade-color($secondary, 15%) !important;
    @include text-contrast(shade-color($secondary, 15%));
  }
}

.bg-success {
  @include text-contrast($success);

  &.clickable:hover {
    background-color: shade-color($success, 15%) !important;
    @include text-contrast(shade-color($success, 15%));
  }
}

.bg-info {
  @include text-contrast($info);

  &.clickable:hover {
    background-color: shade-color($info, 15%) !important;
    @include text-contrast(shade-color($info, 15%));
  }
}

.bg-warning {
  @include text-contrast($warning);

  &.clickable:hover {
    background-color: shade-color($warning, 15%) !important;
    @include text-contrast(shade-color($warning, 15%));
  }
}

.bg-danger {
  @include text-contrast($danger);

  &.clickable:hover {
    background-color: shade-color($danger, 15%) !important;
    @include text-contrast(shade-color($danger, 15%));
  }
}



// Subtle variant backgrounds
.bg-primary-subtle {
  background-color: tint-color($primary, 80%);
  @include text-contrast(tint-color($primary, 80%));

  &.clickable:hover {
    background-color: tint-color($primary, 70%) !important;
    @include text-contrast(tint-color($primary, 70%));
  }
}

.bg-secondary-subtle {
  background-color: tint-color($secondary, 80%);
  @include text-contrast(tint-color($secondary, 80%));

  &.clickable:hover {
    background-color: tint-color($secondary, 70%) !important;
    @include text-contrast(tint-color($secondary, 70%));
  }
}

.bg-success-subtle {
  background-color: tint-color($success, 80%);
  @include text-contrast(tint-color($success, 80%));

  &.clickable:hover {
    background-color: tint-color($success, 70%) !important;
    @include text-contrast(tint-color($success, 70%));
  }
}

.bg-info-subtle {
  background-color: tint-color($info, 80%);
  @include text-contrast(tint-color($info, 80%));

  &.clickable:hover {
    background-color: tint-color($info, 70%) !important;
    @include text-contrast(tint-color($info, 70%));
  }
}

.bg-warning-subtle {
  background-color: tint-color($warning, 80%);
  @include text-contrast(tint-color($warning, 80%));

  &.clickable:hover {
    background-color: tint-color($warning, 70%) !important;
    @include text-contrast(tint-color($warning, 70%));
  }
}

.bg-danger-subtle {
  background-color: tint-color($danger, 80%);
  @include text-contrast(tint-color($danger, 80%));

  &.clickable:hover {
    background-color: tint-color($danger, 70%) !important;
    @include text-contrast(tint-color($danger, 70%));
  }
}

// GRAY
.bg-gray-100 {
  background-color: $gray-100;
  @include text-contrast($gray-100);

  &.clickable:hover {
    background-color: $gray-200 !important;
    @include text-contrast($gray-200);
  }
}

.bg-gray-200 {
  background-color: $gray-200;
  @include text-contrast($gray-200);

  &.clickable:hover {
    background-color: $gray-300 !important;
    @include text-contrast($gray-300);
  }
}

.bg-gray-300 {
  background-color: $gray-300;
  @include text-contrast($gray-300);

  &.clickable:hover {
    background-color: $gray-200 !important;
    @include text-contrast($gray-200);
  }
}

.bg-gray-400 {
  background-color: $gray-400;
  @include text-contrast($gray-400);

  &.clickable:hover {
    background-color: $gray-200 !important;
    @include text-contrast($gray-200);
  }
}

.bg-gray-500 {
  background-color: $gray-500;
  @include text-contrast($gray-500);

  &.clickable:hover {
    background-color: $gray-200 !important;
    @include text-contrast($gray-200);
  }
}

.bg-gray-600 {
  background-color: $gray-600;
  @include text-contrast($gray-600);

  &.clickable:hover {
    background-color: $gray-200 !important;
    @include text-contrast($gray-200);
  }
}

.bg-gray-700 {
  background-color: $gray-700;
  @include text-contrast($gray-700);

  &.clickable:hover {
    background-color: $gray-200 !important;
    @include text-contrast($gray-200);
  }
}

.bg-gray-800 {
  background-color: $gray-800;
  @include text-contrast($gray-800);

  &.clickable:hover {
    background-color: $gray-200 !important;
    @include text-contrast($gray-200);
  }
}

.bg-gray-900 {
  background-color: $gray-900;
  @include text-contrast($gray-900);

  &.clickable:hover {
    background-color: $gray-200 !important;
    @include text-contrast($gray-200);
  }
}

/* Scroll To Top Button */
.btn-scroll-to-top {
  position: fixed;
  visibility: hidden;
  right: 10px;
  width: 44px;
  height: 44px;
  background-color: $primary;
  opacity: 0;
  border-radius: 100%;
  border: none;
  z-index: 1031;
  transition: all ease .1s;

  i {
    color: white;
    font-size: 1.2em;
  }

  &.show {
    visibility: visible;
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }
}


// SECONDARY
.bg-secondary-100 {
  background-color: $orange-100;
  @include text-contrast($orange-100);
}

.hover-secondary-chip:hover {
  background-color: $orange-200;
  @include text-contrast($orange-200);
}

.bg-secondary-200 {
  background-color: $orange-200;
  @include text-contrast($orange-200);
}

.bg-secondary-300 {
  background-color: $orange-300;
  @include text-contrast($orange-300);
}

.bg-secondary-400 {
  background-color: $orange-400;
  @include text-contrast($orange-400);
}

.bg-secondary-500 {
  background-color: $orange-500;
  @include text-contrast($orange-500);
}

.bg-secondary-600 {
  background-color: $orange-600;
  @include text-contrast($orange-600);
}

.bg-secondary-700 {
  background-color: $orange-700;
  @include text-contrast($orange-700);
}

.bg-secondary-800 {
  background-color: $orange-800;
  @include text-contrast($orange-800);
}

.bg-secondary-900 {
  background-color: $orange-900;
  @include text-contrast($orange-900);
}


// PRIMARY
.bg-primary-100 {
  background-color: $blue-100;
  @include text-contrast($blue-100);
}

.hover-primary-chip:hover {
  background-color: $blue-200;
  @include text-contrast($blue-200);
}

.bg-primary-200 {
  background-color: $blue-200;
  @include text-contrast($blue-200);
}

.bg-primary-300 {
  background-color: $blue-300;
  @include text-contrast($blue-300);
}

.bg-primary-400 {
  background-color: $blue-400;
  @include text-contrast($blue-400);
}

.bg-primary-500 {
  background-color: $blue-500;
  @include text-contrast($blue-500);
}

.bg-primary-600 {
  background-color: $blue-600;
  @include text-contrast($blue-600);
}

.bg-primary-700 {
  background-color: $blue-700;
  @include text-contrast($blue-700);
}

.bg-primary-800 {
  background-color: $blue-800;
  @include text-contrast($blue-800);
}

.bg-primary-900 {
  background-color: $blue-900;
  @include text-contrast($blue-900);
}

// SUCCESS
.bg-success-100 {
  background-color: $green-100;
  @include text-contrast($green-100);
}

.hover-success-chip:hover {
  background-color: $green-200;
  @include text-contrast($green-200);
}

.bg-success-200 {
  background-color: $green-200;
  @include text-contrast($green-200);
}

.bg-success-300 {
  background-color: $green-300;
  @include text-contrast($green-300);
}

.bg-success-400 {
  background-color: $green-400;
  @include text-contrast($green-400);
}

.bg-success-500 {
  background-color: $green-500;
  @include text-contrast($green-500);
}

.bg-success-600 {
  background-color: $green-600;
  @include text-contrast($green-600);
}

.bg-success-700 {
  background-color: $green-700;
  @include text-contrast($green-700);
}

.bg-success-800 {
  background-color: $green-800;
  @include text-contrast($green-800);
}

.bg-success-900 {
  background-color: $green-900;
  @include text-contrast($green-900);
}

// WARNING
.bg-warning-100 {
  background-color: $yellow-100;
  @include text-contrast($yellow-100);
}

.hover-warning-chip:hover {
  background-color: $yellow-200;
  @include text-contrast($yellow-200);
}

.bg-warning-200 {
  background-color: $yellow-200;
  @include text-contrast($yellow-200);
}

.bg-warning-300 {
  background-color: $yellow-300;
  @include text-contrast($yellow-300);
}

.bg-warning-400 {
  background-color: $yellow-400;
  @include text-contrast($yellow-400);
}

.bg-warning-500 {
  background-color: $yellow-500;
  @include text-contrast($yellow-500);
}

.bg-warning-600 {
  background-color: $yellow-600;
  @include text-contrast($yellow-600);
}

.bg-warning-700 {
  background-color: $yellow-700;
  @include text-contrast($yellow-700);
}

.bg-warning-800 {
  background-color: $yellow-800;
  @include text-contrast($yellow-800);
}

.bg-warning-900 {
  background-color: $yellow-900;
  @include text-contrast($yellow-900);
}

// DANGER
.bg-danger-100 {
  background-color: $red-100;
  @include text-contrast($red-100);
}

.hover-danger-chip:hover {
  background-color: $red-200;
  @include text-contrast($red-200);
}

.bg-danger-200 {
  background-color: $red-200;
  @include text-contrast($red-200);
}

.bg-danger-300 {
  background-color: $red-300;
  @include text-contrast($red-300);
}

.bg-danger-400 {
  background-color: $red-400;
  @include text-contrast($red-400);
}

.bg-danger-500 {
  background-color: $red-500;
  @include text-contrast($red-500);
}

.bg-danger-600 {
  background-color: $red-600;
  @include text-contrast($red-600);
}

.bg-danger-700 {
  background-color: $red-700;
  @include text-contrast($red-700);
}

.bg-danger-800 {
  background-color: $red-800;
  @include text-contrast($red-800);
}

.bg-danger-900 {
  background-color: $red-900;
  @include text-contrast($red-900);
}

// INFO
.bg-info-100 {
  background-color: $blue-100;
  @include text-contrast($blue-100);
}

.hover-info-chip:hover {
  background-color: $blue-200;
  @include text-contrast($blue-200);
}

.bg-info-200 {
  background-color: $blue-200;
  @include text-contrast($blue-200);
}

.bg-info-300 {
  background-color: $blue-300;
  @include text-contrast($blue-300);
}

.bg-info-400 {
  background-color: $blue-400;
  @include text-contrast($blue-400);
}

.bg-info-500 {
  background-color: $blue-500;
  @include text-contrast($blue-500);
}

.bg-info-600 {
  background-color: $blue-600;
  @include text-contrast($blue-600);
}

.bg-info-700 {
  background-color: $blue-700;
  @include text-contrast($blue-700);
}

.bg-info-800 {
  background-color: $blue-800;
  @include text-contrast($blue-800);
}

.bg-info-900 {
  background-color: $blue-900;
  @include text-contrast($blue-900);
}

.table {
  overflow-y: visible !important;
}

.dropdown-menu {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
}

/* Remove the highlight specifically for items inside a submenu */
.dropdown-menu .dropdown-menu .dropdown-item {
  background-color: transparent;
  color: inherit;
}

.dropdown-menu .dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-menu .dropdown-item:active {
  background-color: transparent;
  color: inherit;
  cursor: default;
}

.sticky-dropmenu-menu-column {
  max-height: 35vh;
  overflow-y: auto;
}

.modal-body {
  max-height: 80vh;
  overflow-y: auto !important;
}

.dropdown-template-btn {
  max-height: 500px;
  overflow-y: auto;
  max-width: 350px;

  .dropdown-title {
    display: inline-block;
    max-width: 100%;
    text-wrap: wrap;
    white-space: normal;
    overflow-wrap: break-word;
    word-break: break-word;
  }
}

.manage-template {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
}

// Text Colors
.text-label {
  color: $gray-700;
}

.text-placeholder {
  color: $gray-500;
}

// .text-muted {
//   color: $gray-600
// }

.activity-log-hover:hover {
  background-color: $gray-100;
}

.pinned-activity-log {
  background-color: $blue-100;
}

.unavailable-wrapper {
  color: $gray-400;
}


/* For WebKit browsers: Chrome, Safari, Edge */
::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

::-webkit-scrollbar-track {
  background-color: $gray-200;
}

::-webkit-scrollbar-thumb {
  background-color: $gray-600;
  border-color: $gray-200;
  border-radius: 25px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $gray-700;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: $gray-600 $gray-200;
}

/* Base Styles */
.documents-main-container {
  display: flex;
  transition: width 0.3s ease;
  height: 100%; /* Container will take full height */
}

.documents-table-wrapper {
  flex-grow: 1; /* Div goes to available space */
  overflow-y: auto;
}

.documents-side-panel {
  right: -700px;
  width: 0;
  height: 100vh;
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
  transition: width 0.3s ease;
}

.documents-side-panel.open {
  width: 20%;
  right: 0;
  min-width: 500px;
}

.documents-main-content {
  transition: margin-right 0.3s ease;
}

/* Container Queries (Min-width: 600px) */
@container (min-width: 600px) {
  .main-content {
    flex: 1;
  }

  .main-content.open {
    flex: 0.75; /* Shrink the main content to 75% when nav is open */
  }

  .documents-side-panel {
    min-width: 90%; /* Default width for larger screens */
  }
}

/* Container Queries (Max-width: 750px) - Adjusted for earlier trigger */
@container (max-width: 750px) {
  .documents-main-content {
    flex: 1;
  }

  .documents-side-panel {
    min-width: 85%; /* Adjusted for smaller screens */
  }
}

/* Additional responsive behavior for smaller screens */
@media (max-width: 900px) {  /* Expanded for larger small screens */
  .documents-side-panel.open {
    min-width: 80%; /* Increase side panel width to 100% on smaller screens */
  }

  .documents-main-content {
    flex: 1; /* Ensure the main content takes up remaining space */
  }
}


/* Base Styles */
.communications-main-container {
  display: flex;
  transition: width 0.3s ease;
  height: 100%; /* Container will take full height */
}

.communications-table-wrapper {
  flex-grow: 1; /* Div goes to available space */
  overflow-y: auto;
}

.communications-side-panel {
  right: -700px;
  width: 0;
  height: 100vh;
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
  transition: width 0.3s ease;
}

.communications-side-panel.open {
  // width: 100%;
  right: 0;
  min-width: 300px;
}

.communications-main-content {
  transition: margin-right 0.3s ease;
}

/* Container Queries (Min-width: 600px) */
@container (min-width: 600px) {
  .main-content {
    flex: 1;
  }

  .main-content.open {
    flex: 0.75; /* Shrink the main content to 75% when nav is open */
  }

  .communications-side-panel {
    min-width: 90%; /* Default width for larger screens */
  }
}

/* Container Queries (Max-width: 750px) - Adjusted for earlier trigger */
@container (max-width: 750px) {
  .communications-main-content {
    flex: 1;
  }

  .communications-side-panel {
    min-width: 85%; /* Adjusted for smaller screens */
  }
}

/* Additional responsive behavior for smaller screens */
@media (max-width: 900px) {  /* Expanded for larger small screens */
  .communications-side-panel.open {
    min-width: 80%; /* Increase side panel width to 100% on smaller screens */
  }

  .communications-main-content {
    flex: 1; /* Ensure the main content takes up remaining space */
  }
}

.workspace-tab {
  max-width: 250px;
}

[role="uploaded file"] {
  overflow: hidden;
}