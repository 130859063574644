// Custom Navbar

.navbar {
  position: fixed;
  height: $navbar-height;
  width: 100%;
  transition: all 0.25s ease-out;
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;

  &.sidenav-active {
    width: calc(100% - $sidenav-width);
  }
}



// Custom Sidenav

.sidenav {
  position: fixed;
  width: $sidenav-width;
  left: -$sidenav-width;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  transition: left 0.25s ease-out;
  -webkit-transition: left 0.25s ease-out;
  -moz-transition: left 0.25s ease-out;
  -ms-transition: left 0.25s ease-out;
  background-color: $gray-100;

  &.active {
    left: 0;
  }

  .sidenav-logo {
    width: 100%;
    max-height: 125px;
  }
}



// App Wrapper

.app-wrapper {
  position: relative;
  width: 100%;
  left: 0;
  transition: all 0.25s ease-out;
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;

  &.sidenav-active {
    left: $sidenav-width;
    width: calc(100% - $sidenav-width);
  }

  .content-wrapper {
    position: relative;
    margin-top: $navbar-height;
    min-height: calc(100vh - $navbar-height);

    .content-wrapper-inner {
      min-height: calc(100vh - $navbar-height);
    }
  }
}



@media screen and (max-width: 576px) {
  .sidenav {
    width: 100%;
    left: -100%;
  }

  .app-wrapper {

    &.sidenav-active {
      display: none;
    }

    .content-wrapper {
      padding: 0;

      .content-wrapper-inner {
        min-height: calc(100vh - $navbar-height);
      }
    }
  }
}


.meter-nav {
  .nav-item{
    box-sizing: border-box;
    .nav-link {
      padding: 10px 0px 10px 8px;
      box-sizing: border-box;
      &.active {
        border-bottom: 2px solid $primary;
        color: $primary
      }
    }
    &:hover,
    &:focus {
      border-bottom: 2px solid $gray-200;
    }
  }
}
